import {css, html, LitElement} from "lit";
// @ts-ignore
import {WlChecked} from "../events/WlChecked";
import {PropertyValues} from "@lit/reactive-element";
import {pxToRem} from "../styles";
import {WlInput} from "../events/WlInput";

export class WlRangeSlider extends LitElement {
    private value: Number = 50.0;
    private rangeSelectorValue: HTMLOutputElement | null = null;
    private inputRange: HTMLInputElement | null = null;
    private readonly name: string = "";
    private readonly min: number = 1.0;
    private readonly max: number = 100.0;
    private readonly step: number = 1.0;
    private readonly suffix: string = '';
    private readonly stepsLabelsNumber = 5;

    static get properties() {
        return {
            value: {type: Number},
            name: {type: String},
            min: {type: Number},
            max: {type: Number},
            step: {type: Number},
            suffix: {type: String},
            stepsLabelsNumber: {type: Number}
        }
    }

    constructor() {
        super()
    }

    protected firstUpdated(_changedProperties: PropertyValues) {
        this.rangeSelectorValue = this.renderRoot.querySelector(".js-range-slider-input")! as HTMLOutputElement;
        this.inputRange = this.renderRoot.querySelector("input[type=range]")! as HTMLInputElement
        this.updated()
    }

    updated() {
        this.rangeSelectorValue!.value = this.value.toString();
        this.inputRange!.value = this.value.toString();
    }

    private _inputChange(event: Event) {
        event.stopPropagation();
        const element = event.target as HTMLInputElement;
        this.value = Number(element.value);
        this.dispatchEvent(new WlInput(this.value.toString(), element.name));
    }

    static get styles() {
        return css`
            :host {
                display: flex;
                flex-direction: column;
                width: 100%;
                max-width: var(--wl-range-slider-max-width, ${pxToRem(200)});
                gap: ${pxToRem(10)};
                align-items: flex-end;
            }

            .range-slider {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin: 0;
                padding: 0;
                order: 2;
            }

            .steps-labels {
                list-style-type: none;
                display: flex;
                flex-direction: row;
                align-items: start;
                justify-content: space-between;
                margin: 0;
                padding: 0;
                color: var(--wl-range-slider-line-color, #000);
                font-size: ${pxToRem(12)};
                font-weight: 400;
                line-height: ${pxToRem(16.34)};
            }

            .steps-labels li {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                width: ${pxToRem(20)};
                    // gap: ${pxToRem(0)};
            }

            .steps-labels li::before {
                content: '|';
                height: ${pxToRem(8)};
                overflow: hidden;
            }

            .range-slider-input__wrapper {
                width: var(--wl-range-slider-input-width, ${pxToRem(100)});
                height: var(--wl-range-slider-input-height, 100%);
                color: var(--wl-range-slider-input-color, #000);
                display: flex;
                position: relative;
                align-items: center;
                order: 1;
                flex: 1 0 auto;
            }

            .range-slider-input {
                width: 100%;
                height: 100%;
                text-align: right;
                -moz-appearance: textfield; /* Firefox */
                border: ${pxToRem(1)} solid var(--wl-range-slider-input-border-color, #000);
                border-radius: ${pxToRem(4)};
                padding: 0 ${pxToRem(22)} 0 ${pxToRem(10)};
                font-weight: 400;
                font-size: ${pxToRem(14)};
                line-height: ${pxToRem(19.07)};
                letter-spacing: 0;
                color: var(--wl-range-slider-input-color, #000);
            }

            .range-slider-input__suffix {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: left;
                right: ${pxToRem(8)};
            }

            .range-slider-input::-webkit-outer-spin-button,
            .range-slider-input::-webkit-inner-spin-button {
                -webkit-appearance: none; /* Chrome, Safari, Edge, Opera */
            }

            .range-slider-input:focus {
                outline: none;
                border: ${pxToRem(1)} solid #000;
            }

            .range-slider .slider {
                -webkit-appearance: none;
                width: 100%;
                margin: 0;
            }

            .range-slider .slider:focus {
                outline: none;
            }

            .range-slider .slider::-webkit-slider-runnable-track {
                width: 100%;
                cursor: pointer;
                border-radius: ${pxToRem(4)};
                height: var(--wl-range-slider-line-width, ${pxToRem(7)});
                background: var(--wl-range-slider-line-color, #000);
            }

            .range-slider .slider::-webkit-slider-thumb {
                position: relative;
                top: -6px;
                -webkit-appearance: none;
                background: var(--wl-range-slider-point-color, #ecf0f1);
                border: var(--wl-range-slider-point-border-size, ${pxToRem(1)}) solid var(--wl-range-slider-point-border-color, #bdc3c7);
                width: calc(var(--wl-range-slider-point-size, ${pxToRem(20)}) + 2 * var(--wl-range-slider-point-border-size, ${pxToRem(1)}));
                height: calc(var(--wl-range-slider-point-size, ${pxToRem(20)}) + 2 * var(--wl-range-slider-point-border-size, ${pxToRem(1)}));
                border-radius: ${pxToRem(21)};
                cursor: pointer;
                box-shadow: 0 0 ${pxToRem(4)} 0 #00000040;
            }

            .range-slider .slider::-moz-range-track {
                width: 100%;
                cursor: pointer;
                border-radius: ${pxToRem(4)};
                height: var(--wl-range-slider-line-width, ${pxToRem(7)});
                background: var(--wl-range-slider-line-color, #000);
            }

            .range-slider .slider::-moz-range-thumb {
                background: var(--wl-range-slider-point-color, #ecf0f1);
                border: var(--wl-range-slider-point-border-size, ${pxToRem(1)}) solid var(--wl-range-slider-point-border-color, #bdc3c7);
                width: var(--wl-range-slider-point-size, ${pxToRem(20)});
                height: var(--wl-range-slider-point-size, ${pxToRem(20)});
                border-radius: ${pxToRem(21)};
                cursor: pointer;
                box-shadow: 0 0 ${pxToRem(4)} 0 #00000040;
            }

            //
            //input[type="range"]::-moz-range-progress {
            //    background-color: red;
            //}
            //
            //input[type="range"]::-moz-range-track {
            //    background-color: blue;
            //}

            @media (min-width: 600px) {
                :host {
                    flex-direction: row;
                }

                .range-slider {
                    order: 1;
                }

                .range-slider-input__wrapper {
                    order: 2;
                }
            }
        `
    }

    protected render() {
        const steps = this.stepsLabelsValues();
        let labelSuffix = ''
        if (this.suffix !== '') labelSuffix = `${this.suffix}`
        const stepsLabels = steps.map(step => html`
            <li>${Math.round(step)}${labelSuffix}</li>
        `);

        return html`
            <div class="range-slider">
                <input type="range" class="slider" name="${this.name}-slider"
                       ${this.value ? `value="${this.value}"` : ""}
                       min="${this.min}" max="${this.max}" step="${this.step}" @input="${this._inputChange}">
                <ul class="steps-labels">
                    ${stepsLabels}
                </ul>
            </div>
            <div class="range-slider-input__wrapper">
                <input type="number" class="range-slider-input js-range-slider-input"
                       name="${this.name}-input" min="${this.min}" max="${this.max}" step="${this.step}"
                       @input="${this._inputChange}">
                <div class="range-slider-input__suffix">${this.suffix}</div>
            </div>
        `
    }

    private stepsLabelsValues() {
        return Array.from({length: this.stepsLabelsNumber}, (_, i) => i === 0
            ? this.min
            : i === (this.stepsLabelsNumber - 1)
                ? this.max
                : this.min + (this.max - this.min) * i / (this.stepsLabelsNumber - 1));
    }
}
