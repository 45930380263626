import {css, html, LitElement, nothing} from "lit";
import {Value} from "../core/Value";
import {WlAutocompleteOption} from "./autocompleteOption.component";
import {WlSelected} from "../events/WlSelected";
import {WlTagClose} from "../chip/chip.component";
import {pxToRem} from "../styles";
import {PropertyValues} from "@lit/reactive-element";

export class AutocompleteContent extends LitElement {
    private options: Value[]
    public selectedOptions: Value[]
    public searchingText: string
    public name: string | undefined
    public getItems: (text: string) => Promise<Value[]>

    public searchingLabel: string
    public notEnoughCharactersLabel: string
    public noResultsLabel: string

    static formAssociated = true;
    private _internals: ElementInternals;
    private _searching: boolean
    private searchHistory: Value[];

    constructor() {
        super();
        // @ts-ignore
        this._internals = this.attachInternals();
        this.selectedOptions = [];

        this.options = [];
        this.getItems = (_: string) => Promise.resolve([])
        this.searchingText = ""
        this.searchingLabel = "Buscando..."
        this.notEnoughCharactersLabel = "Introduce más de 3 caracteres"
        this.noResultsLabel = "No hay resultados"
        this._searching = false
        this.searchHistory = [];

    }

    static get properties() {
        return {
            selectedOptions: {type: Array, state: true},
            options: {type: Array, state: true},
            searchingText: {type: String, state: true},
            _searching: {type: Boolean, state: true},
            name: {type: String},
            searchingLabel: {type: String, attribute: "searching-label"},
            notEnoughCharactersLabel: {type: String, attribute: "not-enough-characters-label"},
            noResultsLabel: {type: String, attribute: "no-results-label"}
        };
    }

    static get styles() {
        return [css`
            .summary {
                display: flex;
                flex-wrap: wrap;
                gap: ${pxToRem(10)};
                padding: ${pxToRem(12)} ${pxToRem(16)};
                border-bottom: 1px solid var(--wl-control-separator, #8F939F);
            }

            .scrollable-content {
                height: fit-content;
                overflow-y: auto;
                overflow-x: hidden;
            }

            .message {
                padding: ${pxToRem(12)} ${pxToRem(16)};
                box-sizing: border-box;
                display: block;
            }
            
            .search-history {
                background-color: rgba(51, 102, 204, 0.05);
                height: ${pxToRem(44)};
                display: flex;
                align-items: center;
                padding-left: ${pxToRem(10)};
            }
            
            .search-history span {
                color: #3366CC;
                font-weight: 700;
            }  
            
            .search-history::before {
                width: ${pxToRem(24)};
                height: ${pxToRem(24)};
                background-image: url(https://multimedia.lamudi.com.mx/lamudi/mx/icon-history.svg);
                content: "";
                display: inline-block;
                margin-right: ${pxToRem(6)};
                background-repeat: no-repeat;
                background-size: contain;
            }
        `]
    }

    protected render() {
        return html`
            ${this._renderSummary()}
            <div class="scrollable-content" @wl-selected="${this._selectOption}">
                ${this._renderOptions()}
            </div>
        `
    }

    _renderSummary() {
        if (this.selectedOptions.length == 0) return nothing

        return html`
            <div class="summary" @wl-tag-close="${this._removeOption}">
                ${this.selectedOptions.map((chip: Value) => html`
                    <wl-chip withCrossIcon><span>${chip.label}</span></wl-chip>
                `)}
            </div>
        `;
    }

    _renderOptions() {
        if (this._searching) {
            return html`<span class="message">${this.searchingLabel}</span>`
        }
        if (this.searchingText.length == 0 && this.searchHistory.length > 0) {
            return html`
            <div class="search-history">
                <span>Búsquedas recientes</span>
            </div>
            ${this.searchHistory.map(option => {
                return html`
                    <wl-autocomplete-option value="${option.value}" text="${option.label}"></wl-autocomplete-option>
                `;
            })}
        `;
        }
        if (this.searchingText.length < 3) {
            return html`<span class="message">${this.notEnoughCharactersLabel}</span>`
        }

        if (this.options.length == 0) return html`<span class="message">${this.noResultsLabel}</span>`

        return html`
            ${this.options.map(option => {
                let marked = this.wrap(option.label, this.searchingText, "strong")
                return html`
                    <wl-autocomplete-option value="${option.value}" text="${marked}"></wl-autocomplete-option>
                `;
            })}
        `;
    }

    public init(values: Value[], valuesHistory: Value[]) {
        this.selectedOptions = values.map((value) => new Value(value.value, value.label))
        this.searchHistory = valuesHistory.map((valueHistory) => new Value(valueHistory.value, valueHistory.label))
        this._notifyChange("wl-init")
    }

    private wrap(value: string, search: string, tag: string) {
        let position = value.toLowerCase().indexOf(search.toLowerCase())
        if (position == -1) return value

        let pre = value.substring(0, position);
        let wrapped = value.substring(position, position + search.length);
        let rest = value.substring(position + search.length);
        return pre + `<${tag}>${wrapped}</${tag}>` + rest
    }

    protected update(changedProperties: PropertyValues) {
        super.update(changedProperties);
        if (changedProperties.has("searchingText")) {
            this._searching = true;
            this.getItems(this.searchingText).then((options) => {
                this._searching = false;
                this.options = options;
            })
        }
    }

    private _selectOption(event: WlSelected) {
        let options = this.options;
        let searchHistory = this.searchHistory;
        if (options.length == 0) {
            options = searchHistory;
        }
        let selectedOption: Value = options.filter(option => event.value == option.value)[0]
        if (!this.selectedOptions.find(option => option.value == selectedOption.value)) {
            this.selectedOptions = this.selectedOptions.concat([selectedOption]);
            this._notifyChange();
        }
    }

    private _removeOption(event: WlTagClose) {
        let removed: Value = new Value(event.value, event.value)
        this.selectedOptions = this.selectedOptions.filter((option) => option.label.trim() != removed.label.trim());
        this._notifyChange()
    }

    private _notifyChange(eventName: string = "wl-change") {
        this._internals.setFormValue(this.selectedOptions.map(option => option.value).join(","));
        this.dispatchEvent(new CustomEvent(eventName, {bubbles: true, composed: true}));
    }
}

customElements.define('wl-autocomplete-content', AutocompleteContent);